<template>
  <button
    v-bind="attrs"
    :type="type"
    :class="[$style.VButton, mods]"
    :disabled="disabled"
  >
    <span v-if="icon" :class="$style.icon">
      <slot name="icon"></slot>
    </span>
    <slot>
      <span v-if="label">
        {{ label }}
      </span>
    </slot>
  </button>
</template>

<script setup lang="ts">
type ButtonSize = "default" | "medium" | "small";
type ButtonColor = "primary" | "secondary" | "transparent";

interface ButtonProps {
  color?: ButtonColor;
  size?: ButtonSize;
  label?: string;
  type?: "submit" | "reset" | "button";
  block?: boolean;
  disabled?: boolean;
  icon?: boolean;
}

const props = withDefaults(defineProps<ButtonProps>(), {
  color: "primary",
  size: "default",
  label: "",
  type: "button",
  block: false,
  disabled: false,
  icon: false,
});
const attrs = useAttrs();

const style = useCssModule();

const mods = computed(() => ({
  [style[props.color]]: true,
  [style[props.size]]: true,
  [style.disabled]: props.disabled,
  [style.block]: props.block,
}));
</script>

<style module lang="scss">
.VButton {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: $base-font;
  border: none;
  outline: none;
  text-align: center;
  transition: all $base-transition;
  cursor: pointer;
  user-select: none;
  gap: 10px;
  font-size: 2rem;
  line-height: 1;
  font-weight: 450;
}

.primary {
  color: var(--text-btn-primary);
  background-color: $brand-blue;
  background-image: $gradient-blue;
  background-size: 150%;

  &:hover {
    background-size: 100%;
  }

  &:active {
    background-image: none;
  }

  &.disabled {
    background-color: $base-150;
    background-image: none;
  }
}

.secondary {
  color: var(--text-btn-secondary);
  background-color: rgba($brand-blue, 0.1);

  &:hover {
    background-color: rgba($brand-blue, 0.2);
  }

  &:active {
    background-color: rgba($brand-blue, 0.3);
  }

  &.disabled {
    color: $base-0;
    background-color: $base-150;
  }
}

.transparent {
  background-color: transparent;
  color: var(--text-color-4);

  &:hover {
    background-color: var(--primary-block-bg-hover);
  }
}

.default {
  height: 6rem;
  padding: 0 4rem;
  border-radius: $border-radius-s;

  @include respond-to(xs) {
    height: 5rem;
    padding: 0 2rem;
    font-size: 16px;
  }
}

.medium {
  min-height: 5rem;
  padding: 1.2rem 2rem;
  border-radius: $border-radius-s;
  font-size: 1.8rem;
}

.small {
  height: 4rem;
  padding: 0 2rem;
  border-radius: $border-radius-xs;
  font-size: 1.8rem;
}

.disabled {
  cursor: not-allowed;
}

.icon {
  width: 2rem;
  display: flex;
  flex-shrink: 0;
  align-items: center;

  & > svg {
    width: 100%;
  }
}

.block {
  width: 100%;
}
</style>
